<template>
  <div class="card card-custom gutter-b example example-compact" id="hotels">
    <div class="card-header">
      <h3 class="card-title">{{ this.name }}</h3>
    </div>
    <div class="card-body">
      <div id="hotelSearchForm" class="form">

        <div class="row mb-4">
          <div class="col-lg-4">
            <label>{{ $t('Hotel', {value: 'Hotel'}) }}</label>
            <input type="text"
                   class="form-control"
                   autocomplete="off"
                   id="hotel"
                   name="hotel"
                   :placeholder="$t('Hotel name', {value: 'Hotel name'})"
                   maxlength="100"
                   value=""
                   required="required"
                   aria-required="true"
                   :data-msg-required="$t('Please select a hotel.', {value: 'Please select a hotel.'})"
                   :data-autocomplete="this.$envConfig.API_URL + '/autocomplete/accommodation'"
                   data-autocomplete-type="autocomplete"
                   data-autocomplete-method="POST"
                   data-autocomplete-response-data="accommodations"
                   data-autocomplete-columns="id,name"
                   data-autocomplete-label="#name#"
                   data-autocomplete-value="#id#"
                   data-autocomplete-target="#hotelId"
                   data-autocomplete-limit="10"
                   :data-autocomplete-message="$t('No matches found.', {value: 'No matches found.'})"
                   data-autocomplete-exclude-field="id"
                   data-autocomplete-exclude=""
            />
            <input type="hidden"
                   class="form-control m-input "
                   autocomplete="off"
                   id="hotelId"
                   name="hotelId"
                   data-search-key="hotelId"
                   data-search-type="="
                   data-type="number"
                   placeholder=""
                   value=""/>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">

        <table class="table table-striped table-bordered table-hover table-checkable"
               data-table-type="datatable"
               :data-method-url="this.$envConfig.API_URL + '/hotel/list'"
               data-search-form="#hotelSearchForm"
               data-table-default-sort-field="1"
               data-table-default-sort-type="desc"
               id="dataTableHotels">
          <thead>
          <tr>
            <th width="15%">{{ $t('Id', {value: 'Id'}) }}</th>
            <th width="70%">{{ $t('Name', {value: 'Name'}) }}</th>
            <th width="15%">&nbsp;</th>
          </tr>
          </thead>
          <tfoot>
          <tr id="reference" data-table-row-id="id" class="hidden">
            <td>##code##</td>
            <td>##name##</td>
            <td class="nowrap">
              <a href="javascript:void(0)" data-vue-click="showReviewCahnnelModal" data-hotel-id="##id##" class="p-3">
                <i class="far fa-edit"></i>
              </a>

            </td>
          </tr>
          </tfoot>
        </table>


    </div>

    <review-channel-detail
        v-show="isModalVisible"
        @close="closeModal"
        :hotel="showHotel"
        :channel="showReviewChannels"
    />
  </div>

</template>

<script>
import $ from "jquery";
import {coreJs} from "utilPath/coreJs";
import {_elementUtil} from "utilPath/element-util";
import reviewChannelDetail from "./hotel/channels";

export default {
  name: 'Settings',
  components: {reviewChannelDetail},
  data() {
    return {
      name: this.$t('Settings', {value: "Settings"}),
      title: this.$t('Settings', {value: "Settings"}),
      isModalVisible: false,
      showHotel: {
        id: null
      },
      showReviewChannels: {
        id: null
      },
    }

  },
  mounted() {
    coreJs.initContainer({container:'#hotels',name: this.name, title: this.title});
    $(document).on('click', '[data-vue-click="showReviewCahnnelModal"]', (evt) => {
      let reviewId = $(evt.target).parent('a').attr('data-hotel-id');
      this.showModal(reviewId);
    });
  },
  methods: {
    showModal(hotelId) {
      let _this = this;
      _this.showReviewChannels.id = null;
      _this.isModalVisible = true;

      _elementUtil.getService(this.$envConfig.API_URL + '/hotel/' + hotelId, {
        element: null,
        callBack: function (elt, response) {
          _this.setReviewChannelModal(response);
        }
      });
    },
    closeModal() {
      this.isModalVisible = false;
    },
    setReviewChannelModal(response) {
      this.showReviewChannel = response.data.hotel.info;
      this.showHotel = response.data.hotel;

    }

  }
};
</script>