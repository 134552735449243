<template id="review-channel-detail">
  <div id="review-channel-detail-modal">
    <div class="modal-backdrop"></div>
    <div class="modal">

      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <header class="modal-header">
            <h5 class="modal-title">{{ $t('Review Channels', 'Review Channels') }}</h5>
            <button
                type="button"
                class="close"
                @click="close"
            >
              <i aria-hidden="true" class="ki ki-close"></i>
            </button>
          </header>

          <section class="modal-body" v-if="this.hotel.id === null">
            {{ $t('Loading', 'Loading...') }}
          </section>
          <section id="printContainer" class="modal-body " v-else>
          </section>

          <footer class="modal-footer" v-if="this.hotel.id !== null">
            <div class="row w-100">
              <div class="col-6">

              </div>
              <div class="col-6 text-right">
                <button
                    type="button"
                    class="btn btn-secondary mr-3"
                    @click="close"
                >
                  <i class="fa fa-times"></i>
                  {{ $t('Close', {value: 'Close'}) }}
                </button>
                <button type="button"
                        data-print-prop-click="save"
                        data-print-param-loaded="false"
                        class="btn btn-primary">
                  <i class="fa fa-print"></i>
                  {{ $t('Save', {value: 'Save'}) }}
                </button>

              </div>
            </div>
          </footer>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {coreJs} from "utilPath/coreJs";

export default {
  name: 'reviewChannelDetail',
  props: {
    hotel: { },
    channel:{}
  },
  data() {
    return {
      name: this.$t('Review Channel', {value: "Review Channel"}),
      title: this.$t('Review Channel', {value: "Review Channel"}),

    }
  },
  mounted() {
    coreJs.initContainer({container: '#review-detail-modal', name: this.name, title: this.title});
  },
  updated() {
    coreJs.initContainer({container: '#review-detail-modal', name: this.name, title: this.title});
  },
  methods: {
    close() {
      coreJs.close({container: '#review-detail-modal'});
      this.$emit('close');
    },
  }
};
</script>